import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from "../images/logo-concrete-content.svg";
import { Link } from "gatsby";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Impressum | concrete content"
      />
      <section className="max-w-2xl p-4 mx-auto text-sm">
        <div className="text-center">
          <Link className="no-underline px-4" to="/">
            <img
              alt="Concrete Content – Verlag & Kommunikation"
              className="block w-1/4 mx-auto mb-20 hover:opacity-80"
              src={logo}
            />
          </Link>
        </div>
        <h1 className="text-2xl font-bold mb-8">Impressum</h1>
        <p>
          <strong>concrete content UG (hb)</strong>
          <br />
          Verlag und Kommunikation
          <br />
          Vossenbergweg 13 b<br />
          46514 Schermbeck
          <br /> <br />
          Tel.: +49 2853 9569480
          <br />
          E-Mail: post@concrete-content.de
          <br /> <br />
          <strong>Geschäftsführer/ Verantwortlich für die Inhalte:</strong>
          <br />
          Holger Kotzan
          <br />
          post@concrete-content.de
          <br /> <br />
          <strong>USt.IdNr:</strong>
          <br /> DE 348109850
          <br />
          <br />
          <strong>Handelsregister:</strong>
          <br /> Amtsgericht Duisburg 34645
          <br /> <br />
        </p>
        <p>
          <strong>Haftungshinweis:</strong>
          <br />
           Alle Rechte vorbehalten. Text, Bilder, Grafiken sowie deren Anordnung
          sind urheberrechtlich geschützt. Der Inhalt darf nicht kopiert,
          verbreitet, verändert oder Dritten zugänglich gemacht werden.
          <br />
          <br />
            Alle Angaben erfolgen nach bestem Wissen und Gewissen, jedoch ohne
          Gewähr.
          <br />
          <br />
            Wir möchten ausdrücklich betonen, dass wir keinerlei Einfluss auf
          die Gestaltung und die Inhalte der extern verlinkten Seiten haben. Für
          den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
          verantwortlich.
        </p>
      </section>
    </Layout>
  );
}

export default AboutPage;
